import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ChatContent from 'pages/ChatContent'
import PrivateRoute from 'utils/PrivateRoute'
import { withTranslation } from 'react-i18next'
import { Button, FormControlLabel, Stack, Switch } from '@mui/material'
import { StringParam, useQueryParam } from 'use-query-params'

const PREFIX = 'chat'

const classes = {
	secondaryBar: `${PREFIX}-secondaryBar`,
}

const StyledPrivateRoute = styled(PrivateRoute)(({ theme }) => ({
	[`& .${classes.secondaryBar}`]: {
		zIndex: 0,
	},
}))

function Dashboard({ t, location, pageContext, userKey }) {
	const [modeParam, setModeParam] = useQueryParam('mode', StringParam)

	const [mode, setMode] = useState(modeParam || 'legacy')

	useEffect(() => {
		setModeParam(mode)
	}, [mode])

	return (
		<>
			<AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
				<Toolbar>
					<Stack flexDirection={'row'} alignItems="center" justifyContent={'space-between'} gap={2} flex={1}>
						<Typography style={{ textTransform: 'capitalize' }} color="inherit" variant="h5">
							Messages
						</Typography>
						<FormControlLabel
							onChange={() => {
								setMode(mode === 'legacy' ? 'new' : 'legacy')
							}}
							label="Legacy chat"
							control={<Switch size="small" color="default" checked={mode === 'legacy' ? true : false} />}
						/>
					</Stack>
				</Toolbar>
			</AppBar>
			<ChatContent mode={mode} setMode={setMode} userKey={userKey ? decodeURI(userKey) : undefined} />
		</>
	)
}

Dashboard.propTypes = {
	location: PropTypes.object,
}

const DashboardPage = ({ location, ...rest }) => {
	return <StyledPrivateRoute location={location} component={Dashboard} {...rest} />
}

export default compose(withTranslation())(DashboardPage)
